/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.1.3): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Modal from './src/modal'

export default {
  Modal
}
